(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.licenseSearch.controller:LicenseSearchCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.licenseSearch')
    .controller('LicenseSearchCtrl', LicenseSearchCtrl);

  function LicenseSearchCtrl(sports, programmes, categories, licenseTypes, $filter, SearchLicense) {
    var vm = this;
    vm.ctrlName = 'LicenseSearchCtrl';
    vm.currentPage = 1;
    vm.licencesPerPage = 10;

    vm.paginationLabel = {
      page: $filter('translate')('PAGE'),
      rowsPerPage: '',
      of: $filter('translate')('OF')
    };

    vm.hasSearched = false;
    vm.searchName = "";
    vm.searchSurname = "";
    vm.searchSecondSurname = "";
    vm.searchLicenses = searchLicenses;
    vm.getNewPage = getNewPage;

    function searchLicenses() {
      vm.hasSearched = true;
      vm.nameToSearch = vm.searchName;
      vm.surnameToSearch = vm.searchSurname;
      vm.secondSurnameToSearch = vm.searchSecondSurname;
      vm.currentPage = 1;

      query(vm.currentPage, vm.licencesPerPage, vm.nameToSearch, vm.surnameToSearch, vm.secondSurnameToSearch)
    }

    function getNewPage(page) {
      query(page, vm.licencesPerPage, vm.nameToSearch, vm.surnameToSearch, vm.secondSurnameToSearch)
    }

    function query(page, size, name, surname1, surname2) {
      var parameters = {
        page: page,
        size: size,
        name: name,
        surname1: surname1,
        surname2: surname2
      };
      SearchLicense.get(parameters, function (data) {
        vm.licences = [];
        angular.forEach(data.licences, function (responseLicence) {
          var licence = {
            name: responseLicence.name,
            surname1: responseLicence.surname,
            surname2: responseLicence.secondSurname,
            licenseType: _.isNull(responseLicence.licenseTypeId) ? "-" : $filter('translate')("PERSON_TYPES." + findItemById(licenseTypes, responseLicence.licenseTypeId).name),
            sport: _.isNull(responseLicence.sportId) ? "-" : $filter('translate')("SPORTS." + findItemById(sports, responseLicence.sportId).name),
            program: _.isNull(responseLicence.programmeId) ? "-" : $filter('translate')(findItemById(programmes, responseLicence.programmeId).name),
            category: _.isNull(responseLicence.categoryId) ? "-" : $filter('translate')("CATEGORIES." + findItemById(categories, responseLicence.categoryId).name),
            organisationName: _.isNull(responseLicence.organisationName) ? $filter('translate')("COUNCIL") : responseLicence.organisationName,
            teamName: _.isNull(responseLicence.teamName) ? "-" : responseLicence.teamName
          };
          vm.licences.push(licence);
        });
        vm.maxLicences = data.page.count;
      }, function (error) {
        showError(error);
      });
    }


    vm.showError = showError;

    function showError(error) {
      console.log(error);
    }

    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }

  }


}());
